exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-content-strategy-js": () => import("./../../../src/pages/content-strategy.js" /* webpackChunkName: "component---src-pages-content-strategy-js" */),
  "component---src-pages-demo-academics-js": () => import("./../../../src/pages/demo/academics.js" /* webpackChunkName: "component---src-pages-demo-academics-js" */),
  "component---src-pages-demo-admissions-js": () => import("./../../../src/pages/demo/admissions.js" /* webpackChunkName: "component---src-pages-demo-admissions-js" */),
  "component---src-pages-demo-contact-static-js": () => import("./../../../src/pages/demo/contact-static.js" /* webpackChunkName: "component---src-pages-demo-contact-static-js" */),
  "component---src-pages-demo-financial-aid-js": () => import("./../../../src/pages/demo/financial-aid.js" /* webpackChunkName: "component---src-pages-demo-financial-aid-js" */),
  "component---src-pages-demo-funding-your-education-js": () => import("./../../../src/pages/demo/funding-your-education.js" /* webpackChunkName: "component---src-pages-demo-funding-your-education-js" */),
  "component---src-pages-demo-index-js": () => import("./../../../src/pages/demo/index.js" /* webpackChunkName: "component---src-pages-demo-index-js" */),
  "component---src-pages-demo-landing-page-js": () => import("./../../../src/pages/demo/landing-page.js" /* webpackChunkName: "component---src-pages-demo-landing-page-js" */),
  "component---src-pages-demo-mba-js": () => import("./../../../src/pages/demo/mba.js" /* webpackChunkName: "component---src-pages-demo-mba-js" */),
  "component---src-pages-demo-news-js": () => import("./../../../src/pages/demo/news.js" /* webpackChunkName: "component---src-pages-demo-news-js" */),
  "component---src-pages-demo-nursing-js": () => import("./../../../src/pages/demo/nursing.js" /* webpackChunkName: "component---src-pages-demo-nursing-js" */),
  "component---src-pages-demo-student-life-js": () => import("./../../../src/pages/demo/student-life.js" /* webpackChunkName: "component---src-pages-demo-student-life-js" */),
  "component---src-pages-demo-test-page-js": () => import("./../../../src/pages/demo/test-page.js" /* webpackChunkName: "component---src-pages-demo-test-page-js" */),
  "component---src-pages-discovery-and-planning-js": () => import("./../../../src/pages/discovery-and-planning.js" /* webpackChunkName: "component---src-pages-discovery-and-planning-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-architecture-js": () => import("./../../../src/pages/information-architecture.js" /* webpackChunkName: "component---src-pages-information-architecture-js" */),
  "component---src-pages-seo-analytics-and-reporting-js": () => import("./../../../src/pages/seo-analytics-and-reporting.js" /* webpackChunkName: "component---src-pages-seo-analytics-and-reporting-js" */),
  "component---src-pages-ux-design-js": () => import("./../../../src/pages/ux-design.js" /* webpackChunkName: "component---src-pages-ux-design-js" */),
  "component---src-pages-visual-design-js": () => import("./../../../src/pages/visual-design.js" /* webpackChunkName: "component---src-pages-visual-design-js" */)
}

